import { MainHubService } from '@smarttask-app/src/app/services/SignalRHubs/mainHub.service';
import { Injectable, OnChanges } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { SharedService } from '@smarttask-app/src/app/services/AngularSpecific/shared.service';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { OrganizationlistStoreService } from '@smarttask-app/src/app/services/Organization/store/organizationlistStore.service';
import { HubConnectionState } from '@microsoft/signalr';

@Injectable({
    providedIn: 'root',
})
export class OrganizationUserHubService {
    last_organization_id = 0;

    constructor(
        private globals: Globals,
        private sharedService: SharedService,
        private organizationlistStoreService: OrganizationlistStoreService,
        private mainHubService: MainHubService
    ) {
        this.watchSelectedOrganizationId();
        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    watchSelectedOrganizationId = () => {
        this.organizationlistStoreService
            .watchSelectedOrganizationId()
            .subscribe((selected_organization_id: number) => {
                if (selected_organization_id == undefined) {
                    if (this.last_organization_id != null && this.last_organization_id != 0) {
                        this.unSubscribe(this.last_organization_id, this.globals.auth_data.user_id);
                    }
                    return;
                }
                this.subscribeOrganizationUser(selected_organization_id);
            });
    };

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            var selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeOrganizationUser(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeOrganizationUser = (organization_id) => {
        if (this.last_organization_id != null && this.last_organization_id != 0) {
            this.unSubscribe(this.last_organization_id, this.globals.auth_data.user_id).then(() => {
                this.subscribeOrganizationUser_sub(organization_id);
            });
        } else {
            this.subscribeOrganizationUser_sub(organization_id);
        }
    };
    subscribeOrganizationUser_sub = (organization_id) => {
        if (organization_id == undefined || this.globals.auth_data == undefined) {
            return;
        }
        this.subscribe(organization_id, this.globals.auth_data.user_id); //Subscribe
        this.last_organization_id = organization_id;
    };

    trackEvents = () => {
        //Triggered Events
        this.mainHubService.connection.on(SharedEventNameEnum.organization_user_notification_new, () => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.organization_user_notification_new;
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.organization_user_notification_all_archive_state, (archive_state: boolean) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.organization_user_notification_all_archive_state;
            event.obj = {
                archive_state: archive_state
            }
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(
            SharedEventNameEnum.organization_user_notifications_archive_state,
            (notification_ids: Array<number>,archive_state: boolean) => {
                var event = new SharedEventModel();
                event.name = SharedEventNameEnum.organization_user_notifications_archive_state;
                event.obj = {
                    notification_ids: notification_ids,
                    archive_state: archive_state
                };
                this.sharedService.broadcast(event);
            }
        );
    };

    subscribe = (organization_id: number, user_id: number) => {
        try {
            if (this.mainHubService.connection == undefined || this.mainHubService.connection.state != HubConnectionState.Connected) {
                console.log('SignalR Proxy unavailable');
                return;
            }
            this.mainHubService.connection.invoke(
                SharedEventNameEnum.organization_user_subscribe,
                organization_id,
                user_id
            );
        } catch (e) {
            console.error(e);
        }
    };

    unSubscribe = (organization_id: number, user_id: number) => {
        if (this.mainHubService.connection == undefined || this.mainHubService.connection.state != HubConnectionState.Connected) {
            console.log('SignalR Proxy unavailable');
            return Promise.reject();
        }
        try {
            return this.mainHubService.connection.invoke(
                SharedEventNameEnum.organization_user_unsubscribe,
                organization_id,
                user_id
            );
        } catch (e) {
            console.error(e);
            return Promise.reject();
        }
    };
}
