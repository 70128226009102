import { MainHubService } from '@smarttask-app/src/app/services/SignalRHubs/mainHub.service';
import { Injectable, Injector } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { SharedService } from '@smarttask-app/src/app/services/AngularSpecific/shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { OrganizationlistStoreService } from '@smarttask-app/src/app/services/Organization/store/organizationlistStore.service';
import * as signalr from '@microsoft/signalr';
import { CapacityModel } from '@smarttask-common/src/lib/models/Resource/CapacityModel';

@Injectable({
    providedIn: 'root',
})
export class CapacityHubService {
    private modalService: NgbModal;
    last_organization_id: number;

    constructor(
        private injector: Injector,
        private globals: Globals,
        private sharedService: SharedService,
        private organizationlistStoreService: OrganizationlistStoreService,
        //private modalService: NgbModal,
        private mainHubService: MainHubService
    ) {
        setTimeout(() => {
            this.modalService = injector.get(NgbModal);
        });
        this.watchSelectedOrganizationId(); //On Change of organization subscribe to new organization
        this.listenForReconnection(); //On SignalR reconnection after getting disconnected
    }

    watchSelectedOrganizationId = () => {
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((selected_organization_id) => {
            if (selected_organization_id == null || selected_organization_id == undefined) {
                if (this.last_organization_id != undefined) {
                    this.unsubscribe(this.last_organization_id);
                }
            } else {
                this.subscribeToNewOrganization(selected_organization_id);
            }
        });
    };

    listenForReconnection = () => {
        this.sharedService.on(SharedEventNameEnum.signalr_reconnected, () => {
            var selected_organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
            if (selected_organization_id) this.subscribeToNewOrganization(selected_organization_id);
            this.trackEvents();
        });
    };

    subscribeToNewOrganization = (organization_id) => {
        //organization's event and subscribe to new organization's events
        if (this.mainHubService.connection.state == signalr.HubConnectionState.Connected) {
            if (this.last_organization_id != undefined) {
                //Unsubscribe from last Organization's SignalR events
                this.unsubscribe(this.last_organization_id).then(() => {
                    this.subscribe(organization_id);
                });
            } else {
                this.subscribe(organization_id);
            }
            this.last_organization_id = organization_id;
        }
    };

    trackEvents = () => {
        //Triggered Events
        this.mainHubService.connection.on(SharedEventNameEnum.capacity_organization_update, (model: CapacityModel) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.capacity_organization_update;
            event.obj = {
                capacity: model
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.capacity_job_role_update, (job_role: string, model: CapacityModel) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.capacity_job_role_update;
            event.obj = {
                job_role: job_role,
                capacity: model
            };
            this.sharedService.broadcast(event);
        });

        this.mainHubService.connection.on(SharedEventNameEnum.capacity_user_update, (user_id: number, model: CapacityModel) => {
            var event = new SharedEventModel();
            event.name = SharedEventNameEnum.capacity_organization_update;
            event.obj = {
                user_id: user_id,
                capacity: model
            };
            this.sharedService.broadcast(event);
        });
    };

    subscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.capacity_subscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    unsubscribe = (organization_id: number) => {
        try {
            if (this.mainHubService.connection == undefined) {
                console.log('SignalR Proxy unavailable');
                return undefined;
            }
            return this.mainHubService.connection.invoke(SharedEventNameEnum.capacity_unsubscribe, organization_id);
        } catch (e) {
            console.error(e);
        }
        return undefined;
    };

    
}
